module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NLZVKBL","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.grolens.com"},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"grolens","defaultLang":"en-us","pages":[{"type":"Blog_post","match":"/blog/:uid","previewPath":"/blog","component":"/opt/build/repo/src/templates/blogTemplate.js"}],"omitPrismicScript":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"r7hi1pkr","include_in_development":true,"delay_timeout":3000},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
